import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import {
  CvtColorSchema,
  CvtBasicTheme,
  DeviceSizes,
} from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageblockPhotoHalf from '../../components/pageBlockPhotoHalf';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import ImgStriche from '../../images/icons/2er_striche.svg';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${DeviceSizes.laptop}) {
    flex-wrap: wrap;
  }
`;

const BulletPointDiv = styled.div`
  padding: 2%;
`;
const Striche = styled.img`
  width: 3em;
`;

export const InnovationsmanagementPageTemplate = ({
  title,
  subtitle,
  image,
  innovationAbility,
  innovationStructureAndTools,
  innovationStory,
  innovationSpotlight,
}: {
  title: string;
  subtitle: string;
  image: any;
  innovationAbility: any;
  innovationStructureAndTools: any;
  innovationStory: any;
  innovationSpotlight: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{innovationAbility.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(innovationAbility.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h2>{innovationStructureAndTools.headline}</h2>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {innovationStructureAndTools.block.map((item: any, index: number) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                key={'innovationStructureAndTools-' + index}
              >
                <h2>{item.headline}</h2>
                <h3>{item.subheadline}</h3>
                <HtmlContent
                  dangerouslySetInnerHTML={md.render(item.content)}
                  style={CvtBasicTheme.htmlContent.general}
                />
              </div>
            );
          })}
        </div>
      </div>
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgGray,
          color: CvtColorSchema.fontDarkGray,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={innovationStory.image}
            alt="Unternehmensgruppe"
            width="200px"
            height="auto"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>{innovationStory.headline}</h2>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(innovationStory.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!innovationSpotlight.image.childImageSharp
              ? innovationSpotlight.image.childImageSharp.fluid.src
              : innovationSpotlight.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {innovationSpotlight.subheadline}
        </h3>
        <h2
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {innovationSpotlight.headline}
        </h2>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(innovationSpotlight.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
    </section>
  );
};

InnovationsmanagementPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  innovationAbility: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  innovationStructureAndTools: PropTypes.shape({
    headline: PropTypes.string,
    block: PropTypes.array,
  }),
  innovationStory: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
  innovationSpotlight: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

const InnovationsmanagementPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={7}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />

      <BlockStandard
        headline={frontmatter.innovationAbility.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgColor={CvtColorSchema.white}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        textAlignment={CvtBasicTheme.align.left as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.innovationAbility.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <br />
        <br />
        <br />
        <Headline
          variant="h2"
          text={frontmatter.innovationStructureAndTools.headline}
        />
        <FlexContainer>
          {frontmatter.innovationStructureAndTools.block.map(
            (item: any, index: number) => {
              return (
                <BulletPointDiv key={'innovation' + index}>
                  <Striche src={ImgStriche} alt={'CI-Striche'} />
                  <p>conventic Innovation</p>
                  <Headline variant="h2" text={item.headline} />
                  <Headline text={item.subheadline} variant={'h3'} />
                  <HtmlContent
                    dangerouslySetInnerHTML={md.render(item.content)}
                    style={CvtBasicTheme.htmlContent.general}
                  />
                </BulletPointDiv>
              );
            }
          )}
        </FlexContainer>
      </BlockStandard>

      <PageblockPhotoHalf
        headline={frontmatter.innovationStory.headline}
        bgcolor={CvtColorSchema.bgGray}
        indexZ="5"
        imgposition="left"
        image={
          !frontmatter.innovationStory.image.childImageSharp &&
          frontmatter.innovationStory.image.extension === 'jpg'
            ? frontmatter.innovationStory.image.publicURL
            : frontmatter.innovationStory.image.publicURL
        }
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.innovationStory.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </PageblockPhotoHalf>

      <BlockStandard
        headline={frontmatter.innovationSpotlight.headline}
        subheadline={frontmatter.innovationSpotlight.subheadline}
        bgImage={
          !frontmatter.innovationSpotlight.image.childImageSharp &&
          frontmatter.innovationSpotlight.image.extension === 'svg'
            ? frontmatter.innovationSpotlight.image.publicURL
            : null
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontBlack}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.innovationSpotlight.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

InnovationsmanagementPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InnovationsmanagementPage;

export const innovationsmanagementPageQuery = graphql`
  query InnovationsmanagementPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        innovationAbility {
          content
          headline
        }
        innovationStructureAndTools {
          headline
          block {
            headline
            subheadline
            content
          }
        }
        innovationStory {
          headline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        innovationSpotlight {
          headline
          subheadline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
